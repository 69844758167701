:root {
    /*Margins & Paddings*/
    --padding:17px;
    --margin: 1em;
    --vertical-padding: 22px; /* must NOT be a percentage value */
    --vertical-margin: 2em;  /* must NOT be a percentage value */
    /*Breakpoints*/
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 1100px;
    /*General*/
    --container-max-width: 1100px;
    --fixed-header-height: 80px;
    --header-min-height: 80px;
    /*Fonts*/
    --base-font-family: 'Ek Mukta', sans-serif;
    --alternative-font-family: 'Lato', sans-serif;
    --base-font-weight: 300;
    --alternative-font-weight: 700;
}


@import "/theme/theinternshipguide/css/modules/flex-grid.css";   
@import "/theme/theinternshipguide/css/modules/normalize.css";
@import "/theme/theinternshipguide/css/modules/basicstyling.css";
@import "/theme/theinternshipguide/css/modules/slick.css";
@import "/theme/theinternshipguide/css/modules/slicktheme.css";
@import "/theme/theinternshipguide/css/modules/menu.v1.2.css";
@import "/theme/theinternshipguide/css/modules/magnificpopup.css";
@import "/theme/theinternshipguide/css/modules/animate.css";
 
.morelink {margin-bottom:0; }
 .morelink a, .morelink a:hover {color:#fff; font-size:1.1em; }


/*------------------------------------------------------------------------
FORUMÄR------------------------------------------------------------------*/
.boltform  p {margin:0; }
.boltform p.boltform-error { padding: 8px 20px ; margin-bottom: 10px ;   color: #fff ;background-color: rgba(255, 0, 0, 0.8);}
p.boltform-message { margin-bottom: 20px;  color: #202020;}
ul.boltform-error.boltform-error  {  list-style-type:none  ;   margin: 0  ;   padding:10px 0 0;   font-size:0.85em; }
ul.boltform-error li:before { content:"! ";  color:#ff0000; font-weight: bold; }
.boltforms-row.message   {margin-bottom:-10px;  }

/*new internship*/
.newinternship .boltforms-row {width:100%; display:block; margin-bottom: 20px; padding:20px; background:#1D957A; color:#fff;  }
.newinternship .boltforms-row a, .newinternship .boltforms-row a:hover { color:#fff;  }

.boltforms-row.termsconditions  { margin-bottom: 0; padding-bottom: 0; }
.newinternship .boltforms-row:last-of-type, .contact-form .boltforms-row:last-of-type  {width:100%; display:block; margin:0px; padding:0px; background:none; }
.boltforms-row.slug {display:none; }
.boltforms-row.termsconditions:after { content:""; display:table; clear:both; }
.boltform-row.recaptcha  {  background: #1D957A; display: block;margin-bottom:-10px; padding: 20px;width: 100%;}
.contact-form .boltform-row.recaptcha  {padding: 20px 0;}
p.boltform-message { background: #6EB990 !important; border: medium none !important;border-radius: 0 !important; color: #fff !important;padding: 20px !important;}
.newinternship .boltforms-row.boltforms-row select {color: #606060;}
#newinternship_durationmin {width: 100px; }
#newinternship_durationmax {width: 100px; }
#newinternship_industry {width: 100%; }
.filterdrop option {font-size:0.85em; padding: 3px 0; }

#newinternship_submit, #contactform_submit {width:100%; background:#1F2636; border-radius:0; border:none;  margin:0; 
letter-spacing: 0.5pt; text-transform:uppercase; font-weight:400; color:#fff;  }
#contactform_submit:hover{ background:#1F2636;  }
#newinternship_submit:hover{ background:#1F2636;  }
#newinternship_description {min-height: 500px !important; }
 
 
/*FORMULÄR terms&conditions*/
.termsconditions .boltforms-label {display: block;  }
.termsconditions .boltforms-error {display:block; float:left;  }
.termsconditions p {display:block; float:left; max-width: calc(100% - 50px); }
.termsconditions .boltforms-value {display:  block;  float:left;  margin: -2px 0 0 20px;  padding: 0; }
.termsconditions .boltforms-value:after {content:""; display:table; clear:both;  }

/*FORMULÄR styling labels & text*/
.newinternship .boltform h2 {font-size:1.6em; padding: 10px 20px 10px;  margin: 0;  background:#1F2636; color:#fff;  }
.newinternship .boltform h3 {font-size:1.2em; margin: 0;  }
.newinternship .boltform label, 
.newinternship .boltform select, 
.newinternship .boltform option,
.newinternship .boltform p, 
.newinternship .boltform em {text-align:left; font-weight:300; color: #fff; font-size:0.95em; }
.newinternship .boltform em {color:#fff; padding:0; margin:0;}
.newinternship .boltform label {padding:0; margin:0; }
span.boltforms-value {display:block; padding-top: 5px; }
.boltforms-row label, .boltforms-row button { margin-bottom: 0.2em;}
.newinternship .boltform option {color:#606060; }

/*FORMULÄR styling fields*/
input[type="color"], input[type="date"], input[type="datetime-local"], input[type="datetime"], input[type="email"], 
input[type="file"], input[type="month"], input[type="number"], input[type="password"], input[type="phone"], 
input[type="range"], input[type="search"], input[type="tel"], input[type="text"], input[type="time"], input[type="url"], select, textarea {
border:none; border-radius: 0;}
.boltforms-row input, .boltforms-row textarea {width:100%; color:#606060; margin-bottom: 5px;}   

/*FORMULÄR internship area*/
#newinternship_internshiparea:after {content:""; display:table; clear:both; }
#newinternship_internshiparea label {float:left; font-size: 1.2em; text-transform: uppercase;  margin: 0 5px 5px 0; width:calc(25% - 5px); min-height:100px; padding:  20px;  text-align:center; background:rgba(255, 255, 255, 0.15);  }
#newinternship_internshiparea input { float:left; width:auto; min-width: 30px;  margin-right: -30px; position: relative; z-index: -1; }
#newinternship_internshiparea input:checked + label { background:#1F2636 !important; color:#fff; }
#newinternship_internshiparea label.required::after {display:none;}

/*FORMULÄR tag format*/
#newinternship_areatopicsitanalytics:after , #newinternship_areatopicsdigitaldesign:after, #newinternship_rewards:after,
#newinternship_areatopicsmarketingpr:after, #newinternship_areatopicsfinancebusinessdevelopment:after, #newinternship_deadline:after 
{content:""; display:table; clear:both; }

#newinternship_areatopicsitanalytics  label, #newinternship_areatopicsdigitaldesign label, #newinternship_rewards label,
#newinternship_areatopicsmarketingpr label, #newinternship_areatopicsfinancebusinessdevelopment label , #newinternship_deadline label
{float:left; display:block; width:auto;  margin-right: 5px;  margin-bottom: 5px;  padding: 6px 20px;  background: rgba(255, 255, 255, 0.15);  }

#newinternship_areatopicsitanalytics  input, #newinternship_areatopicsdigitaldesign input, #newinternship_rewards  input,
#newinternship_areatopicsmarketingpr input, #newinternship_areatopicsfinancebusinessdevelopment input , #newinternship_deadline input
{ float:left; width:auto; min-width: 30px; margin-right: -30px; position: relative; z-index: -1; }

#newinternship_areatopicsitanalytics input:checked + label,#newinternship_areatopicsdigitaldesign input:checked + label,
#newinternship_rewards input:checked + label,#newinternship_areatopicsfinancebusinessdevelopment input:checked + label,
#newinternship_areatopicsmarketingpr  input:checked + label ,#newinternship_deadline input:checked + label
{ background:#1F2636 !important; color:#fff; }
 
/*FORMULÄR duration*/
.newinternship .durationmin  {margin-bottom:-16px; padding-bottom:0;}
.newinternship .durationmax {margin-bottom: -16px;padding: 0 20px;}
.boltforms-row.title {margin-bottom: -5px;padding: 20px 20px 0;}
.newinternship .boltforms-row.companyname  {margin-bottom: -5px;padding: 20px 20px 0;}
.boltforms-row.industry {margin-bottom: -16px;padding: 20px 20px 0;}
.boltforms-row.linkedin {margin-bottom: -5px;padding: 20px 20px 0;}

/*CONTACT FORM------------------------------*/ 
.before-you-contact .boltform  {width:100%; display:block; margin-bottom: 20px; padding:20px; background:#1D957A; color:#fff; }
#contactform_iam:after {content:""; display:table; clear:both; }
#contactform_iam label{float:left; display:block; width:100%;  margin-right: -30px;  margin-bottom: 5px;  padding: 6px 20px; background:rgba(255, 255, 255,0.15);   cursor:pointer;  }
#contactform_iam label:hover{ background:rgba(255, 255, 255,0.25);  }
#contactform_iam input{ float:left; width:auto; min-width: 30px; margin-right: -30px; position: relative; z-index: -1; }
#contactform_iam input:checked + label{ background:#1F2636 !important; color:#fff; }

 

/*------------------------------------------------------------------------------------------------ 
GENERAL ----------------------------------------------------------------------------------------*/
body {color:#303030; font-family: var(--base-font-family); font-weight: var(--base-font-weight); line-height:1.35; 
width: 100%; max-width: calc(100% - 160px); margin: 0 auto; position:relative;  }

 

/*wrappers*/
main.wrapper {min-height: calc(100vh - var(--header-min-height)); position:relative; border-top:var(--fixed-header-height) solid transparent; }  
.content-wrapper {padding: 0px 0 50px;   position:relative;  } 
.content-wrapper > .container {padding: 40px ; margin-top: calc((100vh - 160px) * -0.7); min-height: calc((100vh - 160px) * 0.7);
background: linear-gradient(to bottom, rgba(255,255,255,0.55) 0%,rgba(255,255,255,0.85) 200px,rgba(255,255,255,1) 300px, rgba(255,255,255,1) 100%); }
 
.container {width:100%; }
.container.sml {max-width: 900px;}
.container.lrg {max-width: 1300px;}

/*basic styles*/
a, a:hover {color:#404040; font-weight: var(--base-font-weight); text-decoration: none;}

p {margin:0.2em 0 1.2em;}
strong {font-weight:500;}
iframe {max-width:100%;}
 
.content-wrapper p a {color:#1A957C; font-weight:500; }
.content-wrapper p a:hover {color:#1A957C; font-weight:500; text-decoration: underline; }

/*google ads*/
.the-ads { overflow: hidden;}
.the-ads + div {order:-1; }
.the-ads .ads-inner { margin: 0 0 0 20px; }
body.newinternship .the-ads, body.internship .the-ads   {margin-bottom: 20px;  }
body.newinternship .the-ads + div, body.internship .the-ads + div , body.contact .the-ads + div  { order:0; }


/*------------------------------------------------------------------------------------------------ 
LISTS & TABLES ---------------------------------------------------------------------------------*/
.content-wrapper ul {list-style:none; padding: 0 ; margin: -0.5em 0 20px ;}
.content-wrapper ul li {margin:0; padding:0; line-height: 1.35em; } 
.content-wrapper ul li:before {content: "- "; margin:0 0.2em 0 0; font-size:1.2em; font-weight:700; } 
.content-wrapper ol {padding: 0 0 0 15px; margin: -0.5em 0 20px ;}
.content-wrapper ol li {margin:0; padding:0; } 
h3 + ul, h2 + ul {margin-top:5px !Important; }

/*Popup
.homepage .mfp-bg { opacity:0.65; }
.popup-inner { background:#fff; padding:30px; margin:40px auto; position:relative; width:100%; max-width: 550px; }
.popup-inner form button {width: 100%; margin:0; border:none; background:#e4e4e4; }
.popup-inner form input {width: 100%; }
.popup-inner form label span {display:none; }
.popup-inner img {float:right; margin: 0 0 20px 20px; width: 100px; height:auto;  }*/


/*egna klasser*/
.spaltX2 {column-count: 2; column-gap: 50px; text-align:left !important;} /*Delar upp i 2 spalter*/
.shadow {box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);} 
.publishdate {color:#909090; font-size:95%; display:block; clear:both;  }
.record { border-bottom:1px solid #e4e4e4;}
 
/*------------------------------------------------------------------------------------------------ 
IMAGES -----------------------------------------------------------------------------------------*/
img {max-width: 100%; height: auto;}
figure {margin:0; }
.flex > * > img {flex: 0 0 auto;}
.flex > * > img:not([class]) {width: 100%;}

/*artikelbild*/
figure.page-image {float:right; max-width:40%; height:auto; margin: 0 0 2em 2em; padding: 20px; background:#fff; }
figure.page-image img {display:block; width:100%; height:auto;}
figure.page-image figcaption {font-style:italic; color:#606060;} /*bildtext inne på sidor*/ 
 
/*video bakgrundsbild*/
.listing-video {background-size:cover; background-position:center center; min-height:200px; }

/*filelist*/ 
a.file {display:block;}

/*------------------------------------------------------------------------------------------------ 
BUTTONS & ICONS --------------------------------------------------------------------------------*/
.button.button {font-weight: 400; letter-spacing: 0.5pt; text-align: center;   border:none; padding: 8px 0 10px; display:block; 
border-radius:0; background:#e4e4e4; text-transform:uppercase; margin:20px -var(--padding) -var(--vertical-padding); 
width:calc(100% + var(--padding) + var(--padding));  max-width:calc(100% + var(--padding) + var(--padding)); } 

.button.button:hover {background:#1A957C; color:#fff;   }
.contact-banner .button.button {background: rgba(255, 255, 255, 0.1);}  
 

/*ikoner*/
.fa-icon {height:20px; width: auto; vertical-align:middle !important; }
.toptext a {display: inline-block;  margin: 0 2px;}
.toptext .fa-icon {height:22px; width: 30px; fill: #fff;  }
  
/*sharing*/
.sharing {position:fixed; top: 60%; right:0; padding: 7px; background:#6EB990; opacity:0; z-index: 10;   transition:opacity 0.5s ease-in 0s; }
.sharing .fa-icon {fill:#fff; display:block; height:20px; width: 25px; margin: 10px 0;  }
body.scrolled .sharing {  opacity:1;    transition:opacity 0.5s ease-in 0s; }


/*------------------------------------------------------------------------------------------------ 
FONTS ----------------------------------------------------------------------------------------*/
h1, h2, h3, h4, h5, h6 {letter-spacing:0; line-height:1.1; padding: 0; margin: 0; 
font-family: var(--alternative-font-family); font-weight: var(--alternative-font-weight); }
h1{font-size: 2.4em; margin: 0 0 0.4em; text-transform:uppercase; }
h2{font-size: 1.65em;}
h3{font-size: 1.25em;}  
h4, h5, h6 {font-size: 1.3em; }
h2.highlight {font-size:1.45em; padding: 0 0 0.7em; color:#1A957C; font-weight:700; }

/*------------------------------------------------------------------------------------------------ 
TOPPEN ----------------------------------------------------------------------------------------*/
.toptext { position:absolute; right:20px; top:100px; z-index:9; }  
.imagetop {min-height:calc(100vh - 160px); background-position: center center; background-size:cover; position: relative;}

/*------------------------------------------------------------------------------------------------ 
STARTSIDA ----------------------------------------------------------------------------------------*/
/*slick slider*/
.slick-wrapper {min-height:calc(90vh - 160px); flex-wrap: wrap; position:relative;  }
.slick-track, .slick-substitute {display:flex; flex-direction:row;}
.slick-slider, .slick-substitute > div, .slick-slide {min-height:calc(90vh - 160px); margin:0; }
.slick-slider, .slick-substitute, .slick-substitute > div { top:0; left:0; bottom:0; right:0; width:100%; z-index:0;}
.slick-slide, .slick-substitute > div {background-size:cover; background-position: center top; display:flex !important;}   
.slick {visibility: hidden;}  .slick-initialized {visibility: visible;}  .slick-list, .slick-track {height:100%;}

/*START Text i slick*/ 
.starttext {padding: 50px 50px 170px; width:100%; max-width:70%;  z-index: 0; color:#fff;   text-shadow: 0 0 20px rgba(0, 0, 0, 0.5);  }
.starttext h1 {font-size: 4.5em; line-height:0.93em;   font-weight: 900; margin: 0 0 20px; text-transform: none;}
.starttext p {margin-bottom:10px; font-size: 1.4em; line-height:1.1em; font-weight:400; } 

/*START uneven boxes*/
.uneven {position:relative; z-index:1; padding: 0 20px;  }
.different  {margin-top: -135px; position:relative;  }
.different > div {padding: 35px 60px 30px 30px; color:#fff; background:#1A957C; min-height:170px;   }
.different h2 {font-size:1.8em;   padding: 0 0 5px;}
.different p {font-size:1.2em;   line-height:1.2em; }
.different a {color:#fff;   }
 
.hirewithus {margin-top: -110px; position:relative; }
.hirewithus > div {  background:#fff;  margin-left: -40px;  min-height: 120px;padding: 20px 25px 25px;}
.hirewithus h2 {font-size:1.7em;   padding: 0 0 3px;}
.hirewithus p {font-size: 1.1em;line-height: 1.1em; }
.hirewithus p:last-child, .different p:last-child {margin-bottom:0; }

/*START Internship categories start*/
.boxes {background: #e4e4e4 ; margin-top: 40px;  padding:30px 20px; position: relative; z-index: 0; }
.box { background:#fff; margin-bottom:0; text-align:center; }
.box.center { justify-content: flex-start;}
.box h3   {  font-size: 1.15em; min-height: 45px;  padding: 10px; }
.box h3 a {color:#fff; text-transform:uppercase; }
.box > a {min-height: 80px; }
.box-text p:last-of-type { margin:0.2em 0 0.5em;}
.box .fa-icon {display:block;  margin: 0px auto ; vertical-align:top !important;}

.box:nth-child(2) h3 { background: #6db994;}
.box:nth-child(3) h3 { background: #1A957C;}
.box:nth-child(4) h3 { background: #d6dd55;}
.box:nth-child(4) h3 a { color: #404040;}
.box:nth-child(5) h3 {background: #1F2635; }

.box:nth-child(2) a .fa-icon {fill: #6db994; height: 55px; width: 60px;}
.box:nth-child(3) a .fa-icon {fill:#1A957C; height: 38px; width:45px;}
.box:nth-child(4) a .fa-icon {fill:#d6dd55; height: 50px;width: 55px;}
.box:nth-child(5) a .fa-icon {fill:#1F2635; height:40px; width:45px;}

/*START Promoted internships*/
.promotedinternships { background:#1F2636;  margin-top: 40px; padding:30px 20px 40px; color:#fff;   }
.promotedinternships > div > div:first-child { margin-bottom:0;  }
.promotedinternships h2 { color:#fff; text-transform:uppercase; font-size:1.7em; padding-bottom: 10px;    }
 

/*internship search & boxes*/
.filterdrop { border: none;border-radius: 0; color: #404040;  padding:0; margin:0;width:100%; } 
.filterdiv {margin-bottom:0;  }
.filterdiv h3{margin-bottom:10px;  }
.filter {padding: 30px 25px 40px; background:#1D957A; color:#fff;  margin-top: 20px;   }
.internships .searchbar {padding: 20px 0 0; margin-bottom:0;  }
.internships .searchform {margin: 0; padding:0;  max-width: 100%; width:100%; }
.internships input[type="search"]  {width:100%; color:#606060;  margin:0; box-sizing: border-box; padding: 2px 10px; min-height: 35px;}

/*internships*/
.tabs {margin-right:0; margin-left:0;margin-top: 40px;  }
[data-showhide]{display:none;}
.tabs .internship {box-shadow:  0 0 0 2px #e4e4e4;  }
.internship  {background:#fff; color:#252525;  }
.internship .internship-logo  {  margin: 0 20px 10px; min-height: 55px; }
 
.best { text-align:center; position:relative; padding-top: 15px;    }
.best:before { content:""; position:absolute; top:0; left: 10%; right:10%; height:3px; width: 80%; background:#d0d0d0;   }
.best h3 {font-size:1.1em; padding: 0 0 5px; }
.best strong {font-size:1em; padding: 0 0 5px; display:block; line-height:1.1em;  }
.best span {padding: 0 0 5px; display:block; clear:both; }
 
.locdur {text-align:center;  font-size: 0.95em; max-width: calc(100% + var(--padding) + var(--padding));   margin-left: -var(--padding);   margin-right: -var(--padding); }
.locdur > div {padding:10px; }
.locdur > div .fa-icon {height:40px ;width: 45px; display:block;   margin: 0 auto 5px;  vertical-align:top !important; }

.less { max-width: calc(100% + var(--padding) + var(--padding));   margin-left: -var(--padding);   margin-right: -var(--padding); 
 margin-bottom: -var(--vertical-padding); padding: 10px var(--padding) ; background:#e4e4e4; border-top: 1px solid #d0d0d0;  }
.less span {text-transform:uppercase; font-size:1.1em; font-weight:500;  }

.internship[data-internshiparea="areatopicsitanalytics"] .fa-icon {fill:#6DB994;  }
.internship[data-internshiparea="areatopicsmarketingpr"] .fa-icon {fill:#D6DD55; }
.internship[data-internshiparea="areatopicsfinancebusinessdevelopment"] .fa-icon {fill:#1F2635;  }
.internship[data-internshiparea="areatopicsdigitaldesign"] .fa-icon {fill:#1A957C;  }

.internship[data-internshiparea="areatopicsitanalytics"] .button.button {background:#6DB994; color:#fff;}
.internship[data-internshiparea="areatopicsmarketingpr"] .button.button{background:#D6DD55;color:#404040;  }
.internship[data-internshiparea="areatopicsfinancebusinessdevelopment"] .button.button {background:#151c2b; color:#fff; }
.internship[data-internshiparea="areatopicsdigitaldesign"] .button.button {background:#1A957C; color:#fff;}



/*------------------------------------------------------------------------------------------------ 
CONTENT----------------------------------------------------------------------------------------*/
/*slick referenser*/
.slickreferenser { min-height: 10px; color: #303030;  font-size: 1.2em;font-weight: 500;line-height: 1.4em; margin-bottom: 0; padding: 10px 0 15px;  }
.slickreferenser.container.sml {  max-width: 700px; margin: 0 auto; }
body.newinternship .slickreferenser {padding: 10px 0 20px;  }
.slickreferenser .slick-slide { min-height:10px;display:flex;  }
.slickreferenser em {display:block; line-height:1.2em;  }
.slickreferenser em:before {content:'"'; font-size:1.3em; font-weight:bold; font-family: "Times New Roman",serif ;    }
.slickreferenser em:after {content:'"'; font-size:1.3em; font-weight:bold; font-family: "Times New Roman",serif    }

/*Internship detaisl page*/
.internship-details {background:#1A957C; color:#fff; }
.internship-details .fa-icon {fill:#fff; height:35px; width: 45px;   }
.internship-details h2 { font-size:1.4em;   display:block; }
.internship-details.internship-details p a { color:#fff; }
.thetitle {background:#1A957C;  }
.thetitle .fa-icon { height:40px; width: 50px;  }

.mains {text-align:center;  padding-bottom: 10px; padding-top: 10px; }
.mains span {display:block; clear:both;font-size:1.2em; line-height:1.1em; text-transform:uppercase;    }
.mains a {color:#fff; }
.mains .fa-icon {display:block; margin: 0 auto 10px; }
.mains:nth-child(2) {background:rgba(255, 255, 255, 0.05); }
.mains:nth-child(3) {background:rgba(255, 255, 255, 0.1);}
.mains:nth-child(4) {background:rgba(255, 255, 255, 0.15);}
.thetags {background:#1F2636; }
.thetags > div  {margin-bottom: 10px; }
.thetags > div:last-child  {margin-bottom: 0px; }

.company-details {background:#fff; box-shadow: 0 0 0 1px #e4e4e4; }
.company-details .thetitle {background:#e4e4e4;   }
.company-details h2 { font-size:1.4em; text-align:left  ; display:block;  }

.internship-logo-page { background:#fff; width:100%; height:60px; padding: 20px;  }
.internship-logo-page img {  width:auto !important; max-width:100%; max-height:100%; }
.basics, .links {padding-top:0;  }
.basics {padding-bottom: 10px; }
.basics span  {font-size:1.15em; line-height:1.1em; text-transform:uppercase; padding-bottom: 5px; font-weight: 500; }
.links a {  display:block;padding-bottom: 5px;  }
.links .fa-icon {fill:#1F2636; height:25px; width: 30px; margin: 0 10px 0 0 ;  vertical-align: bottom !important;}

.description {padding-left:0; padding-right:0;  }


/*FAQ*/
.question {padding:0; margin-bottom: 5px; border:none; }
.question h2 {font-size:1.2em; padding: 8px 20px; background:#6EB990; color:#fff; cursor:pointer;  }
.question.aktiv h2 { background:#1D957A; }
.svarpafragan {display:none; padding: 5px 0 0; }
.faqspalt h3 {padding: 0 0 10px; margin:0; }

 
/*pagenotfound*/
.pagenotfound { min-height: calc(100vh - var(--fixed-header-height) - 80px); position:relative;  text-align:center;  font-size:1.1em; background:#e4e4e4;}
.pagenotfound .logo img { max-height: 90px; max-width: calc(100vw - 100px); margin-bottom: 30px; }
.pagenotfound h1 { font-size:1.9em; text-transform:none; color:#707070;  padding: 0; margin: 0 ; }
.pagenotfound ul {list-style-type:none; padding: 0; margin: 0.5em auto; width:100%; max-width: 550px; }
.pagenotfound li {margin:0 0 6px; padding:0 0 6px; width:100%; text-align:center; border-bottom: 1px dashed #d0d0d0;  }

/*gallery*/
.image-gallery, .logotype-gallery {padding: 2em 0 0; margin-top:1em; border-top: 1px solid #d0d0d0; }
.gallery-image {box-shadow: 1px 1px 0 0 #e4e4e4;} .gallery-image:nth-child(5n + 5) {box-shadow: 0px 1px 0 0 #e4e4e4;}
.gallery-image a {display:block; }
.gallery-image img {padding:20px; display: block;}
.thumb-title {font-size:90%; padding: 0 20px 20px; display:none; }  

/*logo gallery
.logotype-image {min-height: 70px; } 
.logotype-image div, .logotype-image a {text-align:center; width: 100%; flex:0 0 auto; display:block;} 
.logotype-image img {height:auto;} .standing  {width:40%; } .squarish  {width:40%; } .landscape  {width:55%; }.longlandscape {width:70%; }.extralonglandscape {width:100%; }
*/

/*magnific styling*/ 
.mfp-bg {background: #000; opacity:0.9;}  .mfp-arrow {top:40%; } 
.mfp-arrow, .mfp-arrow:before, .mfp-arrow-left:before, .mfp-arrow-right:before,.mfp-arrow:after, .mfp-arrow-left:after, .mfp-arrow-right:after {border:none ; margin-left:0; margin-right:0;  }
.mfp-arrow-left:before {content:"<"; } .mfp-arrow-right:before {content:">"; }
.mfp-arrow-left:before, .mfp-arrow-right:before {height:100px; width:100px; color:#fff; font-size:5.5em; font-family: 'Quicksand', sans-serif; opacity:0.9;}
.mfp-arrow-left {margin-left:20px; }  .mfp-arrow-right {margin-right:20px; } 
button.mfp-close { font-size: 4em; margin:0 -2px 0 0; opacity: 0.9; font-family: Quicksand; }
  



/*------------------------------------------------------------------------------------------------ 
FOOTER & KONTAKT--------------------------------------------------------------------------------*/
/*karta
.map-canvas {height: 35vh; margin-top:2em;}
.map-canvas img {max-width: none;}
.markerLabels{ overflow:visible !important;}
.fa-map-marker{background: url("/extensions/local/intendit/intenditExt/web/black/map-marker.svg") center center/cover; padding: 10px;}*/

/*footer*/
footer {padding: 30px 0 50px; width:100%; min-height:80px; text-align:center; }
footer, footer a, footer a:hover {color:#969696; }
footer a {font-size:0.9em;  }
footer .center {flex-direction: row; }
footer h4 { padding: 0 0 5px; font-size:1.3em; } 
footer .round {background: rgba(255, 255, 255, 0.2);}
footer svg {fill:#fff; }
footer .copytext {display:block; clear:both; letter-spacing: 0.5pt;  padding-bottom: 10px; }

/*cookiebar
.cookies { position:fixed;  z-index:100;  bottom: 0;   left:0;   padding: 10px 0;  width:100%;  min-height: 50px;   background:rgba(0, 0, 0, 0.85);  
color:#fff;   display:flex;  text-align:center;}
.cookiebutton, .cookiebutton:hover {  display:inline-block;  padding: 2px 8px;  margin: 0 0 0 10px;  color:#fff;  font-weight:400;  border:1px solid #fff;}*/

/*------------------------------------------------------------------------------------------------ 
HEADER ----------------------------------------------------------------------------------------*/
header {background:#fff; text-transform:uppercase;  font-family: var(--alternative-font-family); position:fixed; width:calc(100% - 160px); top:0;  
z-index:10; min-height:var(--header-min-height);  }
header > div {min-height:inherit;} 

/*Logotype*/
.logo { padding:10px 15px 10px 5px; box-sizing:border-box; max-width: calc(100% - 60px);} 
.logo a, .logo a:hover {padding:0; display:block; } 
.logo a img {max-height: 48px; width:auto; display:block; max-width:100%;  }

/*Standard meny + hovers*/
nav {background:inherit; } 
header nav ul.menu > li {padding: 0; margin:0; }
nav ul.menu > li:last-child {border:none; padding-right:5px; }
nav ul.menu > li.opportunities a {background: #e6e55a;color: #fff; font-weight: 900; letter-spacing: 0.5pt;  margin: 0 10px;
 min-width: 145px;  padding: 6px 15px; text-align: center;  text-shadow: 0 0 10px rgba(169, 175, 33, 0.4); }
nav ul.menu > li.opportunities a:hover, nav ul.menu > li.opportunities.active a { background:#dde436; color:#fff;  }

nav ul.menu > li.newinternship a { background:#1A957C; color:#fff;  text-shadow:none;  font-weight: 700;  letter-spacing: 0.5pt; padding: 6px 15px; }
nav ul.menu > li.newinternship a:hover, nav ul.menu > li.newinternship.active a { background:#1A957C; color:#fff;   }

li.opportunities, li.opportunities a{position:relative;}
.startpage li.opportunities a:after, .homepage li.opportunities a:after { background: #e6e55a;opacity:1;   color: #fff; content: "Straight to Internships";  display: block;
 font-size: 32px; font-weight: 900; height: 145px;  left: 145px; letter-spacing: 1pt; line-height: 1.2em; min-width: 310px; padding: 30px 30px;
 position: absolute; text-shadow: 0 0 10px rgba(169, 175, 33, 0.4); text-transform: uppercase; top: 58px; transform: rotate(90deg);transform-origin: left top 0; 
transition: opacity 0.5s ease-in 0s;}

body.scrolled.startpage li.opportunities a:after, body.scrolled.homepage li.opportunities a:after {opacity:0; transition: opacity 0.5s ease-in 0s;}

nav ul.menu li a { padding: 6px 8px; margin:0; letter-spacing:0; color:#acacac; font-weight:400;  }
nav ul.menu li a:hover, nav ul.menu li.active a {  color:#acacac;  }
nav ul.menu li.dropdown > a:after { content: ">"; transform: rotate(90deg); vertical-align: middle; display: inline-block; font-family: 'Quicksand', sans-serif;  font-size: 1em; margin: 0 0 0 2px ;  }
nav .menu .dropdown ul  {border:none; padding: 10px 0; left:20px; }
nav ul.menu > li:last-child > ul { left: auto;right: 0; text-align:right; }

 
/*Mobile menu - fixed header*/
@media (max-width: 1100px)
{
.the-ads + div {order:1; }   
.the-ads   {margin-bottom: 20px;  }   
 
header.flex {flex-wrap: nowrap;  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3); min-height:70px; } 
main.wrapper { border-top: 70px solid transparent;   }
    
nav ul.menu.show { max-height:calc(100vh - var(--header-min-height)); padding: 10px 0; background:rgba(255, 255, 255, 0.95);}  
nav ul.menu li, .mobile-menu nav ul.menu li a, .mobile-menu nav .menu .dropdown ul {border:none; background:none; }
nav ul.menu li a {  color: #606060; padding: 4px 8px;}
nav ul.menu li a:hover, nav ul.menu li.active a {  color:#606060;  }

/*special effect*/
.startpage li.opportunities a::after, .homepage li.opportunities a::after {display:none;}   
nav ul.menu > li.opportunities a, nav ul.menu > li.newinternship a { background:none;color: #606060; font-weight: 400; letter-spacing: 0; margin: 0; 
min-width: 1px; padding:  4px 8px; text-align: left; text-shadow: none;}
.menu .dropdown {background:   none  }
nav .menu .dropdown.dropdown ul {padding:0; left:0px; margin: 0; } 
.menu .sub-menu-items li a {margin: 0 10px;} 
nav ul.menu li.dropdown > a:after {display:none; }
nav ul.menu > li.opportunities a, nav ul.menu > li.newinternship a {text-align: left !important;} .menu .sub-menu-items li a { margin: 0;}
nav ul.menu > li:last-child > ul {text-align: left;}   
      
/*logo*/   
.logo { padding: 10px ; align-items: flex-start; }

/*menu icon*/
nav #menu-icon {align-self: flex-start; padding: 20px 20px 20px 0;}
nav #menu-icon span, nav #menu-icon:before, nav #menu-icon:after { border-radius:3px; color:#1A957C;}
}


/*------------------------------------------------------------------------------------------------ 
MEDIA QUERIES ----------------------------------------------------------------------------------*/

@media (max-width: 1500px) 
{  
.content-wrapper {padding-left: 20px; padding-right: 20px; } 
h1{font-size: 2.2em;}
    
}


@media (max-width: 1400px) 
{  

/*general*/
body {max-width: calc(100% - 80px);}
header {width: calc(100% - 80px); }
.content-wrapper > .container {  padding: 40px 20px;} 
.logo a img {  max-height: 45px;}
nav ul.menu li a {  padding: 6px;}   
        
/*start*/
.promotedinternships {  padding: 20px;   }
    
/*ointernships*/   
.filterdiv, .searchbar { margin-left: 10px; margin-right:10px; }   
.filter {  padding: 20px 10px 30px;}   
  
}



/*----------------------------Allt under 1300px----------------------------*/
@media (max-width: 1300px) 
{  
/*start*/
.starttext {padding: 80px 30px 120px; max-width: 65%;  }
.starttext h1 {font-size: 3.4em;  }
.starttext p { font-size: 1.3em; line-height:1em;   } 
.uneven {padding:0; }  
.different   { margin-top: -100px;  }   
.hirewithus  { margin-top: -60px;} 
.different > div { padding: 20px 50px 20px 20px;  }   
.hirewithus > div { margin-left: -30px;   padding: 20px;} 
.different h2, .hirewithus h2 {font-size:1.6em;  padding-bottom: 5px;}
  
.internship .internship-logo { min-height: 45px;}   

/*general & menu*/
h1{font-size: 2em;}
body {max-width: calc(100% - 60px);}
header {width: calc(100% - 60px); }   
.content-wrapper {padding-left: 0px; padding-right: 0px; } 
nav ul.menu li a {  font-size: 0.92em;    padding: 6px 5px;}  
nav ul.menu li:first-child {display:none; } 
nav ul.menu > li.opportunities a, nav ul.menu > li.newinternship a {  min-width: 130px; padding: 6px 5px; text-align: center;}  
.startpage li.opportunities a::after, .homepage li.opportunities a::after { font-size: 27px; height: 130px;left: 130px; min-width: 240px; padding: 30px 0; top: 57px;} 
     
/*Container*/
.container.lrg {width: 96%;}   
.logo a img {max-height: 40px;}   
    
}
   
/*----------------------------Allt under 1100px----------------------------*/
@media (max-width: 1100px)
{
/*Start*/
.slick-wrapper, .slick-slider, .slick-substitute > div, .slick-slide {min-height: calc(90vh - 160px);}   
.slick-prev, .slick-next {height: 60px; width: 60px;}
.slick-prev:before, .slick-next:before { font-size: 60px;}  
.starttext {padding: 150px 2% 120px ; max-width: 80%;  }
.starttext h1 {font-size: 3em; margin: 0 0 15px; line-height:1em;   }
.starttext p { font-size: 1.3em; line-height:1em;   } 
      
.promotedinternships {  padding: 20px 0;   }
    
/*internships*/   
.filterdiv { margin-bottom: 10px;}
.filter.filter > .twelve { flex-basis: calc(100% - 1em * 2) !Important; flex-grow:0  !Important;   }
    
/*internship details*/   
.internship-details h2 { font-size: 1.3em;} 
.mains span { font-size: 1em;} 
    
/*general*/
body { max-width: 100%;}
header {width:  100%; }
nav ul.menu li:first-child {display:inline-block; }   
h1 {font-size: 2em;}    
    
/*slick ref*/ 
.slickreferenser.slick-slider .slick-slide { min-height:10px; }
.slickreferenser.slick-slider { min-height: 10px;   }

/*faq*/
.faqspalt > .six:first-child {margin-left:0; flex-basis: calc(50% - 1em);}   
.faqspalt .six:last-child {margin-right:0;flex-basis: calc(50% - 1em); }   

/*flex*/    
.flex.m-no-lr-margin > * {margin-left:0 !important; margin-right:0 !important; }
.flex.m-no-padding > * {padding:0 !important;}
   
/*Containers*/
.container {width: 96%;}  
header.container {width: 100%;}  
   
/*form stuff*/ 
#newinternship_internshiparea label { width: calc(50% - 5px);}    
#newinternship_areatopicsitanalytics label, #newinternship_areatopicsdigitaldesign label, 
#newinternship_rewards label, #newinternship_areatopicsmarketingpr label, 
#newinternship_areatopicsfinancebusinessdevelopment label, #newinternship_deadline label { padding: 6px 10px;} 
       

}

/*----------------------------Allt under 800px----------------------------*/
@media (max-width:800px)
{
/*Startsida*/ 
.slick-wrapper, .slick-slider, .slick-substitute > div, .slick-slide {  min-height: 50vw;  }
.starttext { padding: 100px 2% 20px;  max-width: 100%;}
.starttext h1 { font-size: 2.8em;}
.starttext p {font-size: 1.15em;}  
.slick-wrapper + .starttext {margin-top:0;}

.container.lrg.uneven { width: 100%; }   
.different {margin-top: 0;} 
.different > div {padding: 30px 2%; min-height: 10px;} 
.hirewithus { margin-top:  0px; } 
.hirewithus > div { margin-left: 0px; padding: 30px 2%; min-height: 10px;}    

.boxes {  margin-top: 0px; min-height: 10px; padding: 30px 2%; position: relative;}  
.box {margin-bottom: 20px; }   
.internship .internship-logo { min-height: 55px;} 
.boxlink {transition: none !important;  animation-name: none;}  
    
.promotedinternships { margin-top:0; } 

/*general*/
.imagetop {min-height:50vw;  border-bottom: 10px solid #d0d0d0;}
.content-wrapper {margin-top: 0;}     
.content-wrapper > .container {  border: 1px solid #e4e4e4; background:  #fff  ; margin-top: calc( 50vw * -0.3); min-height: 10px; } 
.content-wrapper > .container { padding: 30px 15px;}
.toptext {right: 10px;  top: 90px; }
    
/*faq*/  
.faqspalt > .s-twelve:first-child {  flex-basis: calc(100%);}   
.faqspalt .s-twelve:last-child { flex-basis: calc(100%); }   
    
footer {padding: 20px 0; }
footer .copytext { padding-bottom: 0;}      

   
.toptext .fa-icon {height: 22px;width: 31px;}
.sharing {background:#1F2636;}
.sharing .fa-icon { height: 18px; margin: 11px 0;width: 22px;}

    
/*Fontsize*/ 
body {font-size: 0.95em; }
h2.highlight { font-size: 1.25em;}  
  
    
/*gallerithumbs*/
.gallery-image.gallery-image {box-shadow: none;}
.gallery-image img {padding: 0.8em;}    
 
/*Bild*/
figure.page-image {margin: 0 0 1.3em 1.3em;}
     
/*Footer & Contact*/   
footer .s-twelve {border-bottom: 1px solid; margin: 0 auto; max-width: 400px; padding: 20px 0 10px; text-align: center; }
footer .s-twelve:last-child {border-bottom: none; }   
    
/*Not found*/
.pagenotfound li a {padding: 10px 20px; width: 100%;}
 
/*Containers*/
.container.sml {width: 96%;} 
    
/*flex margin & paddings*/    
.margin > .twelve, .margin > .m-twelve, .margin > .s-twelve  {margin: 0 auto var(--margin) !important; flex-grow:1 !important;}    
.flex.s-no-padding > * {padding:0 !important;}  
}


/*---------------------------MEDIA XS - upp till 550-----------------------------*/
@media (max-width:550px)
{
/*Startsida*/ 
.starttext { padding: 80px 2% 10px;}
.starttext h1 { font-size: 2.4em;}
.starttext p {font-size:1.1em; } 
.different h2, .hirewithus h2 {font-size: 1.65em;}

/*general*/
main.wrapper {min-height: 10px; }  
.content-wrapper {padding: 30px 0;}
.toptext {display:none; }   
.content-wrapper > .container { padding: 30px 10px; margin-top: -20vw;}    
h1 {font-size:1.8em;} 

/*imagetop*/  
.imagetop {min-height:50vw;}
     
/*internships*/
.filter { padding: 20px 10px; }
.filter.filter > .twelve {  flex-grow:1  !Important;   }
   
.tabs {margin-left: 10px; margin-right: 10px;}
.internship .internship-logo {min-height: 45px;}    
.best h3 { font-size: 1.3em;}
.locdur {margin:0; text-align:left; padding-bottom: 10px;padding-top: 15px;   }
.locdur > div {padding:0; }
.locdur span {font-size: 1.1em; font-weight: 500;text-transform: uppercase;}

/*internship details*/ 
.internship-details h2 {  font-size: 1.2em;} 
.mains:nth-child(4) { text-align: left;}
.thetags > .ten {margin-bottom: 15px;  }
   
/*forms*/
#newinternship_internshiparea label { min-height: 10px; width: 100%; float:none;  }
.before-you-contact .boltform { padding: 20px 10px;}
.g-recaptcha > div {width: 100% !important; max-width: 100% !important; overflow: hidden; }
  
/*faq*/  
.question h2 { font-size: 1.1em; padding: 8px 10px;}
       
/*image*/
figure.page-image {margin: 0 0 10px 10px; padding:0; border:none;  }   
    
/*sidfot*/
footer  {font-size: 1.1em; line-height: 1.5; word-spacing: 2pt; padding: 30px 0 40px; }     
     
/*spalt*/    
.spaltX2 {-webkit-column-count: 1; -moz-column-count: 1; column-count: 1; -webkit-column-gap: 0px; -moz-column-gap: 0px; column-gap: 0px; text-align:none;} 
   
/*Kontaktbanner*/   
.contact-banner {padding: 30px 0;}

/*map*/
.map-canvas {width:100%; max-height:20vh;} 
    
/*Containers*/
.container.xs {width: 96%;}  
    
/*flex margin & paddings*/ 
.margin > .xs-twelve {margin: 0 auto var(--margin) !important; flex-grow:1 !important;}
.flex.xs-no-padding > * {padding:0 !important;} 

/*logo*/
.logo {padding:0; }    
.logo a img {padding:15px 10px 5px 10px;  max-height: 54px;  }   
    
    
}


@media (max-width: 375px)
{    
/*start*/
.slick-wrapper, .slick-slider, .slick-substitute > div, .slick-slide  {  min-height: 70vw;  }
.starttext {padding: 20px 2% 20px; }
.starttext h1 {margin: 0; font-size:2em !important;} 
.starttext p {display:none; } 
.toptext {display:none; }  
.different h2, .hirewithus h2 {font-size: 1.45em;}
  
/*other*/ 
.content-wrapper > .container {  margin-top: -70px;}
.tabs {margin-left:  0px;margin-right:  0px;}
.internship .internship-logo { min-height: 40px;}
#newinternship_areatopicsitanalytics label, #newinternship_areatopicsdigitaldesign label, #newinternship_rewards label, 
#newinternship_areatopicsmarketingpr label, #newinternship_areatopicsfinancebusinessdevelopment label, #newinternship_deadline label {width: 100%;}  
      
 
/*font*/        
h1, h2, h3, h4, h5, h6 { hyphens: auto;}
h1 {font-size:1.7em !important; }  
 
/*bild*/
figure.page-image { display:none; }     
}

 
/*------------------------------------------------------Make FB widget responsive-------------------------------------------------------*/
.fb_iframe_widget, .fb_iframe_widget span, .fb_iframe_widget iframe[style], .twitter-timeline {width: 100% !important;}
